.productImage{
    border: 0.21rem;
    border-style: solid;
    border-color: $grey;
    padding: 1rem;
    margin: 1rem;
    text-align: center;
    display: flex;  
    justify-content: center;
}

.img {
    width: 100%;
    height: 90%;
    cursor: pointer;
    padding-bottom: 1rem;
}

.productImageLabel{
    color: $dark-grey;
    font-weight: bold;
    height: 7%;
    font-size: medium;
}

.dialogProduct{
    position: fixed;
    border: 2;
    border-style: solid;
    padding: 5px;
    margin: 5px;
    background-color: white;
    top: 10%;
    left: 30%;
    width: 40vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 700px) {
        top: 10%;
        left: 15%;
        width: 70vw;
        height: 80vh;
    }
    @media screen and (max-width: 500px) {
        top: 20%;
        left: 15%;
        width: 70vw;
        height: 60vh;
    }
}
.dialogImg{
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.iconImage{
    height: 1em;
    width: auto;
    display: inline-block;
}
