// colors
$grey : #848484;
$dark-grey : #343434;

//Spacing
$s-size : 1.2rem;
$m-size : 1.6rem;
$l-size : 3.2rem;
$xl-size : 4.8rem;
$desktop-breakpoint : 45rem;
