.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loader__image {
  height: 6rem;
  width: 6rem;
}