* {
  box-sizing: border-box;
}

html{
    font-size: 62.5%;
    margin-top: 0;
    height: 100%;
}

body {
    background: url('/Images/background.jpg') no-repeat center center fixed;
    background-size: cover;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: $m-size;
    line-height: 1.6;
}

.page {
    padding: 0rem calc((100vw - 900px) / 2);
    margin : 2rem;
}
.content{
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 1%;
    padding: 2rem;
    margin-top: 1rem;

}

.link {
    outline: none;
    text-decoration: none;
    padding: 2px 1px 0;
    
}

.video {
    width: 100%;
    margin: 1rem 0 1rem 0;
}